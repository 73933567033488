import React from "react"
import Layout from "../components/Layout"

import Hero from "../components/modules/HeroBanner"
import SEO from "../components/SEO"

import Testimonial from "../components/elements/Testimonial"
import {HalfImageContent, HalfContent, HalfImage } from "../components/modules/HalfImageContent"


export default () => (

  <Layout>
    <SEO title="About Barron Carpets &amp; Flooring" description="Find out more about Barron Carpets experience in flooring sales and installation." />
    <Hero alt="Laminate flooring being installed" filename="laminate-installation" title="Find out how our team can transform your property" label="Contact Us" link="/contact" />

    <section className="container dark section-spacer md:w-9/12 lg:w-8/12">
      <h1>About Us</h1>
      <p className="lead">
      Barron Carpets and Floors are a family run business with over 50 years of experience in the industry. Our goal is to provide a friendly and professional service for our customers at the best possible price. Come to us with any flooring <a href="/contact" title="Contact Barron Carpets">enquiries</a> and we will advise the best solution for you.
      </p>


     <HalfImageContent>
          <HalfImage filename="yoga-studio" alt="Yoga studio and mats" order="order-last" />
          <HalfContent order="order-first">
          <h2>Why Choose Us?</h2>
            <p>Here are just some of the reasons our customers love us:</p>
            <ul className="list list-disc" >
              <li>We can fit all the flooring we sell across Scotland.</li>
              <li>We work around you, your schedule and preferences to Covid-19 guidelines.</li>
              <li>We are a one-stop solution for retail and installation.</li>
            </ul>
          </HalfContent>
      </HalfImageContent>
     
      <blockquote >
      <span className="text-3xl">Great service, highly recommend these guys! Amazing job.</span>
        <cite>Louise Brennan, Edinburgh</cite>
      </blockquote> 
      <HalfImageContent>
          <HalfImage filename="installation" alt="Tools and materials" order="order-first" />
          <HalfContent order="order-last">
          <h2>What We Offer</h2>
            <p>We do more than just fit carpets:</p>
            <ul className="list list-disc" >
              <li>Domestic installation</li>
              <li>Commercial installations</li>
              <li>Sports and specialist flooring installation.</li>
            </ul>
          </HalfContent>
      </HalfImageContent>

    </section>

    <Testimonial quote="Great service friendly chatty and most of all amazing quality carpet and luxury vinyl tiles for my stairs it’s brought the whole staircase and landing out I will definitely be recommending Chris to everyone 10/10" cite="Kaerryann Fraser, Edinburgh" />


  </Layout>
)